.root {
}

.button {
  height:           30px;
  width:            fit-content;
  font-size:        12px;
  color:            white;
  background-color: black;
  padding:          0 5px;
  margin:           5px;
  border:           0px solid #FFFFFF;
  cursor:           pointer;
  font-family:      'Barlow Condensed',sans-serif;
  letter-spacing:   1px;
  font-weight:      600;
  text-align: left;
}


.button:hover {
  color: black;
  background-color: white;
}

.button2 {
  height:           30px;
  width:            fit-content;
  font-size:        12px;
  color:            white;
  background-color: black;
  padding:          0 5px;
  margin-top:       5px;	
  border:           0px solid #FFFFFF;
  cursor:           pointer;
  font-family:      'Barlow Condensed',sans-serif;
  letter-spacing:   1px;
  font-weight:      600;
  text-align: left;
}

.button2:hover {
  color: black;
  background-color: white;
}


.ligne{
 width: 100%
}

.logo{
  margin-top:4px;
  margin-left:4px;
  height:64px;
}
