.root {
  margin-top:       70px;

  height:           100%; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;

  background:      #f5f5f5;
  color:  #000000;
}

.contain {
  height:          100%; 
  width:           100%;
  max-width:       800px; 
  margin:          auto;
  padding:         5px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}


.grid{
  height:                fit-content;
  width:                 100%;
  
  display:               grid;
  grid-template-columns: 10% 1% 29% 2% 18% 1% 29% auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.grid_email_nom{
  grid-column: 1;
  grid-row: 1;
}

.grid_email_data{
  grid-column: 3;
  grid-row: 1;
}

.grid_password_nom{
  grid-column: 5;
  grid-row: 1;
}

.grid_password_data{
  grid-column: 7;
  grid-row: 1;
}

.grid_password2_nom{
  grid-column: 5;
  grid-row: 2;
}

.grid_password2_data{
  grid-column: 7;
  grid-row: 2;
}

.grid_firstname_nom{
  grid-column: 1;
  grid-row: 3;
}

.grid_firstname_data{
  grid-column: 3;
  grid-row: 3;
}

.grid_lastname_nom{
  grid-column: 5;
  grid-row: 3;
}

.grid_lastname_data{
  grid-column: 7;
  grid-row: 3;
}

.grid_sex_nom{
  grid-column: 1;
  grid-row: 4;
}

.grid_sex_data{
  grid-column: 3;
  grid-row: 4;
}

.grid_birthday_nom{
  grid-column: 5;
  grid-row: 4;
}

.grid_birthday_data{
  grid-column: 7;
  grid-row: 4;
}

.grid_address_nom{
  grid-column: 1;
  grid-row: 5;
}

.grid_address_data{
  grid-column: 3/8;
  grid-row: 5;
}

.grid_address2_nom{
  grid-column: 1;
  grid-row: 6;
}

.grid_address2_data{
  grid-column: 3/8;
  grid-row: 6;
}

.grid_zipCode_nom{
  grid-column: 1;
  grid-row: 7;
}

.grid_zipCode_data{
  grid-column: 3;
  grid-row: 7;
}

.grid_city_nom{
  grid-column: 5;
  grid-row: 7;
}

.grid_city_data{
  grid-column: 7;
  grid-row: 7;
}

.grid_country_nom{
  grid-column: 1;
  grid-row: 8;
}

.grid_country_data{
  grid-column: 3;
  grid-row: 8;
}

.grid_phoneNumber_nom{
  grid-column: 5;
  grid-row: 8;
}

.grid_phoneNumber_data{
  grid-column: 7;
  grid-row: 8;
}

.grid_website_nom{
  grid-column: 1;
  grid-row: 9;
}

.grid_website_data{
  grid-column: 3;
  grid-row: 9;
}

.grid_instagram_nom{
  grid-column: 5;
  grid-row: 9;
}

.grid_instagram_data{
  grid-column: 7;
  grid-row: 9;
}

.grid_facebook_nom{
  grid-column: 1;
  grid-row: 10;
}

.grid_facebook_data{
  grid-column: 3;
  grid-row: 10;
}

.grid_tiktok_nom{
  grid-column: 5;
  grid-row: 10;
}

.grid_tiktok_data{
  grid-column: 7;
  grid-row: 10;
}

.grid_bio_nom{
  grid-column: 1;
  grid-row: 11;
}

.grid_bio_data{
  grid-column: 3/8;
  grid-row: 11;
}

.grid_rgpd{
  grid-column: 1/9;
  grid-row: 12;
}

.grid_button{
  grid-column: 1/9;
  grid-row: 13;
}

.nom{
  font-size:     16px;
  color:         #969696;
  padding: 0 5px;
  margin: 0;
  place-items: right;
  justify-content: right;
  text-align: right;
  align-items: right;
  vertical-align: middle
}


.field_bloc_input{
  flex-grow:     1;
  height:          fit-content;
  display:         flex;
  place-items:     left;
  justify-content: left;
  text-align:      left;
  align-items:     left;
}

.field_input
 {
  height:        40px;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #ffffff; /* linear-gradient(#333,#222); */
  padding:       0 10px;
  padding:       0;
  box-sizing:    border-box;
}

.field_input:focus
 {
  color:      #000000;
  box-shadow:       0 0 5px rgba(128,0,128,.2),
              inset 0 0 5px rgba(88,26,129,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

.field_input_read_only
 {
  height:        40px;
  flex-grow:     1;
  border:        2px solid #242424;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
  caret-color:   #000000;
  background:    #f5f5f5; /* linear-gradient(#333,#222); */
  margin:        0;
  padding:       0 10px;
  box-sizing:    border-box;
}


.span_eye {
  height:        38px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

@keyframes glow
 {
  0% {
        border-color: #581A81;
        box-shadow:         0 0 5px rgba(88,26,129,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #581A81;
        box-shadow:         0 0 20px rgba(88,26,129,.6),
                      inset 0 0 10px rgba(88,26,129,.4);
       }
}


.required {
  font-size:      0.8em;  
  vertical-align: super;  
  color:          #FF0000;
}

.redIcon {
  color: #FF0000;
}



.espace_haut{
  
  height: 70px;
}

.espace_bas{
  
  height: 45px;
}

.texte_1{
 width:      100%;
 text-align: justify;
 font-size:      18px;
 font-weight: bold;
 color:  #000000;
}

.texte_2{
 width:      100%;
 text-align: justify;
 font-size:      16px;
 color:  #000000;
}

.text_rgpd{
 width:      100%;
 text-align: justify;
 font-size:      16px;
 color:  #000000;
}

.div_vignette {
  height:          fit-content; 
  width:           100%;
  margin:          0;
  padding:         0;
  justify-content: left;
  align-items:     left; 
  align:           left;
  place-items:     left;
  text-align:      left;
  box-sizing:      border-box;
}

.vignette{
 width: 256px;
 margin-left: 70px;
 margin-right: auto;
 
}

.link{

  color: #000000;
}

.trigger_registration{
  height:         50px;
  width:          fit-content;
  font-size:      19px;
  margin-top:     30px;
  padding:        0 15px;
  font-weight:    600;
  letter-spacing: 1px;
  color: #FFFFFF;
 }


.trigger_registration:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
  color: #7F7F7F;
}

.trigger_registration:hover{
  color: #FFFF00;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}

.trigger_registration:disabled:hover{
  color: #7F7F7F;
  border: 1px solid #7F7F7F;
  box-shadow: 0 0 5px rgba(127,127,127,.3),
              0 0 10px rgba(127,127,127,.2),
              0 0 15px rgba(127,127,127,.1),
              0 2px 0 black;
}



.message_ack{
 color:#00FF00
}

.message_error{
 color:#FF0000
}


@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {
}

@media only screen and (max-width: 460px) and (orientation: portrait) {

.root {
  margin-top:       0px;
 }
 
.grid{
  height:                fit-content;
  width:                 100%;
  
  display:               grid;
  grid-template-columns: 49% 2% 49%;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.grid_email_nom{
  grid-column: 1;
  grid-row: 1;
}

.grid_email_data{
  grid-column: 3;
  grid-row: 1;
}

.grid_password_nom{
  grid-column: 1;
  grid-row: 2;
}

.grid_password_data{
  grid-column: 3;
  grid-row: 2;
}

.grid_password2_nom{
  grid-column: 1;
  grid-row: 3;
}

.grid_password2_data{
  grid-column: 3;
  grid-row: 3;
}

.grid_firstname_nom{
  grid-column: 1;
  grid-row: 4;
}

.grid_firstname_data{
  grid-column: 3;
  grid-row: 4;
}

.grid_lastname_nom{
  grid-column: 1;
  grid-row: 5;
}

.grid_lastname_data{
  grid-column: 3;
  grid-row: 5;
}

.grid_sex_nom{
  grid-column: 1;
  grid-row: 6;
}

.grid_sex_data{
  grid-column: 3;
  grid-row: 6;
}

.grid_birthday_nom{
  grid-column: 1;
  grid-row: 7;
}

.grid_birthday_data{
  grid-column: 3;
  grid-row: 7;
}

.grid_address_nom{
  grid-column: 1;
  grid-row: 8;
}

.grid_address_data{
  grid-column: 3;
  grid-row: 8;
}

.grid_address2_nom{
  grid-column: 1;
  grid-row: 9;
}

.grid_address2_data{
  grid-column: 3;
  grid-row: 9;
}

.grid_zipCode_nom{
  grid-column: 1;
  grid-row: 10;
}

.grid_zipCode_data{
  grid-column: 3;
  grid-row: 10;
}

.grid_city_nom{
  grid-column: 1;
  grid-row: 11;
}

.grid_city_data{
  grid-column: 3;
  grid-row: 11;
}

.grid_country_nom{
  grid-column: 1;
  grid-row: 12;
}

.grid_country_data{
  grid-column: 3;
  grid-row: 12;
}

.grid_phoneNumber_nom{
  grid-column: 1;
  grid-row: 13;
}

.grid_phoneNumber_data{
  grid-column: 3;
  grid-row: 13;
}

.grid_website_nom{
  grid-column: 1;
  grid-row: 14;
}

.grid_website_data{
  grid-column: 3;
  grid-row: 14;
}

.grid_instagram_nom{
  grid-column: 1;
  grid-row: 15;
}

.grid_instagram_data{
  grid-column: 3;
  grid-row: 15;
}

.grid_facebook_nom{
  grid-column: 1;
  grid-row: 16;
}

.grid_facebook_data{
  grid-column: 3;
  grid-row: 16;
}

.grid_tiktok_nom{
  grid-column: 1;
  grid-row: 17;
}

.grid_tiktok_data{
  grid-column: 3;
  grid-row: 17;
}

.grid_bio_nom{
  grid-column: 1;
  grid-row: 18;
}

.grid_bio_data{
  grid-column: 3;
  grid-row: 18;
}

.grid_rgpd{
  grid-column: 1/4;
  grid-row: 19;
}

.grid_button{
  grid-column: 1/4;
  grid-row: 20;
}

}

