.root {
  min-height:       fit-content;
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
  
  background:      #f5f5f5;

}

.contain {
  height:          100%; 
  width:           100%;
  padding-left:     150px;
  padding-right:    150px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.grille{
  display: grid;
  grid-template-columns: 66% 33%;
  grid-template-rows: auto; 
}

.ligne1 {
  grid-column: 1/3;
  grid-row: 1;
  text-align:  justify;
}

.left {
  grid-column: 1/3;
  grid-row: 2;
  text-align:  justify;
}

/*
.left {
  grid-column: 1;
  grid-row: 2;
  align: left;
  text-align: left;
  place-items: left;
}

.right {
  grid-column: 2;
  grid-row: 2;
  align: right;
  text-align: right;
  place-items: right;
}
*/

.texte_2{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   26px;
 font-weight: bold;
 box-sizing:      border-box;
 text-wrap: wrap;
}


.texte_1{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 line-height: 1.5em;
 text-wrap: wrap;
}

.texte_1b{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 font-weight: bold;
 text-wrap: wrap;
}


.espace_haut{
  
  height: 40px;
}

.espace_bas{
  
  height: 40px;
}

.link{

  color: #000000;
}


@media only screen and (max-width: 460px) {

  .contain {
    padding-left: 15px;
    padding-right: 15px;
  }

  .texte_1{
    font-size:   17px;
  }

  .texte_1b{
    font-size:   17px;
  }

  .texte_2{
    font-size:   25px;
    box-sizing:      border-box;
  }

  .espace_haut{
    height: 25px;
  }

  .espace_bas{
    height: 25px;
  }
}
