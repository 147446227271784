.root {
  margin-top: 100px;
  height: fit-content; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
  box-sizing: border-box;
  background: #000000;
}

.contain {
  display: flex;
  width: 90%; 
  justify-content: center;
  box-sizing: border-box;
}

.block2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  gap: 20px;
  max-width: 100%; 
  justify-content: center;
  box-sizing: border-box;
}


.video_thumb {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  gap: 10px;
  width: 100%;
}

.image {
  width: 380px;
  height: 218px;
  margin: auto;
}

.title {
  width: fit-content;
  margin: auto;
}

.line_image {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.line_title {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

@media only screen and (max-width: 1920px) {
  .root {
    margin-top: 70px;
    height: 900px; 
  }

  .contain {
    width: 90%; 
    margin-left: 150px;
  }
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .root {
    margin-top: 70px;
    height: 1024px; 
  }

  .contain {
    width: 90%; 
    margin-left: 40px;
  }
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {
  .root {
    margin-top: 70px;
    height: 1170px;
  }

  .contain {
    max-width: 65%; 
    margin-left: auto;
  }
}

@media only screen and (max-width: 460px) and (orientation: portrait) {
  .root {
    margin-top: 0px;
    height: 800px;
  }

  .contain {
    max-width: 90%; 
    margin-left: auto;
  }
}

.titre {
 
 text-align: center;
 margin: auto;
 color: #FFFFFF;
 font-size: 25px;
 font-weight: bold;
}        