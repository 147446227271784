.root {
  min-height:       900px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
 
  background:      #f5f5f5;
  color:           #000000;
  }

.contain {
  height:          100%; 
  width:           100%;
 /* max-width:       800px;*/
  margin-left:     auto;
  margin-right:    auto;
  margin-top:      0px;
  margin-bottom:   0px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.titre{ 
  margin-left:     auto;
  margin-right:    auto;
  padding-top: 30px;
 color:       #000000;
 width:       100%;
 text-align:  center;
 font-size:   32px;
 font-weight: bold;
 box-sizing:  border-box;
 text-wrap:   wrap;
}

.Sigma
 {
  display:       inline-block;

  top:           80px; 
  left:          37px; 
  z-index:       3000;
 }

.Sigma
 {
  display:       inline-block;

  top:           80px; 
  left:          87px; 
  z-index:       3001;
 }

.Fujifilm
 {
  display:       inline-block;

  top:           80px; 
  left:          137px; 
  z-index:       3002;
 }

.Leica
 {
  display:       inline-block;

  top:           130px; 
  left:          37px; 
  z-index:       3003;
 }

.PhaseOne
 {
  display:       inline-block;

  top:           130px; 
  left:          87px; 
  z-index:       3004;
 }

.Profoto
 {
  display:       inline-block;

  top:           130px; 
  left:          137px; 
  z-index:       3005;
 }

.Canon
 {
  display:       inline-block;

  top:           180px; 
  left:          37px; 
  z-index:       3006;
 }

.Ilford
 {
  display:       inline-block;

  top:           180px; 
  left:          87px; 
  z-index:       3007;

 }

.Leofoto
 {
  display:       inline-block;

  top:           180px; 
  left:          137px; 
  z-index:       3008;

 }

.ThinkTank
 {
  display:       inline-block;

  top:           230px; 
  left:          37px; 
  z-index:       3009;
 }

.Laowa
 {
  display:       inline-block;

  top:           230px; 
  left:          87px; 
  z-index:       3010;
 }

.DxO
 {
  display:       inline-block;

  top:           230px; 
  left:          137px; 
  z-index:       3011;
 }

.Zhiyun
 {
  display:       inline-block;

  top:           280px; 
  left:          37px; 
  z-index:       3012;
 }

.Sony
 {
  display:       inline-block;

  top:           280px; 
  left:          87px; 
  z-index:       3013;
 }

.Nikon
 {
  display:       inline-block;

  top:           280px; 
  left:          137px; 
  z-index:       3014;
 }

.Lumix
 {
  display:       inline-block;

  top:           330px; 
  left:          37px; 
  z-index:       3015;
 }

.NationPhoto
 {
  display:       inline-block;

  top:           330px; 
  left:          80px; 
  z-index:       3016;
 }







.logo_partenaire {
  width: 50%;
}

.zoom img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.zoom :hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {
}

@media only screen and (max-width: 460px) and (orientation: portrait) {


  .contain {
    padding-left: 15px;
    padding-right: 15px;
  }

  .espace_haut{
    padding-top: 15px;
    height: 25px;
  }
}
