.root {

  margin-top:       70px;

  height:           1000px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
  
  background-size:  2560px; 
  background-image: url("https://home.lovelightfactory.com/images/fond-welcome-2560x1440.jpg");
}

.contain {
  height:          100%; 
  width:           100%;
  max-width:       800px; 
  margin:          auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.texte{
  padding:         10px;
  background:      #0000007F;

}

.espace_haut{
  
  height: 145px;
}

.espace_bas{
  
  height: 145px;
}

/*
@media only screen and (max-width: 465px) {

  .root {
    background-size:  100% 100%;
    background-image: url("https://home.lovelightfactory.com/images/fond2-sd.jpg"); 
  }

}
*/

@media only screen and (max-width: 1920px) {
  .root {
    height:           1080px; 
    background-size:  1920px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1920-1080.jpg"); 
  }
 }

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .root {
    height:           1024px; 
    background-size:  1440px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-L.jpg"); 
  }
 }

@media only screen and (max-width: 1440px) and (orientation: portrait) {
  .root {

    height:        1170px;

    background-size:  1024px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-P.jpg"); 
  }
 }
 
@media only screen and (max-width: 460px) {
  .root {

    margin-top:   0px;

    height:        800px;

    background-size:  100%;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-464x934-P.jpg"); 
  }
 }
