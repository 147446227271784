.root {
}

.cadrePhoto{

  display:       inline-block;
  font-size:     1em;
  
  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);
  
  margin:        auto;
  overflow:      auto;
  z-index:       9000;
  border-radius: 5px!important;

  outline:       none;
  color:         #868686;
  border:        1px solid #444;
  caret-color:   #FF00FF;
  background:    #000000;
    
  box-sizing: border-box;

  border-radius:   5px 5px 5px 5px;
  overflow-wrap:   break-word;

  text-align:      left;
  place-items:     left;
  justify-content: left;

  width: 99%; 
  height: 99%; 
}

.bloc {
  display:               grid;
  grid-template-columns: 100%;
  grid-gap:              0px;
  grid-template-rows:    50px auto;
}

.bloc_ligne_info {
  grid-column: 1;
  grid-row: 1;
  text-align:      left;
  place-items:     left;
  justify-content: left;
}

.bloc_ligne_photo {
  grid-column:     1;
  grid-row:        2;
}



.photo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: auto;
}



.divCroix {
  display:       inline-block;

  position:      absolute;
  top:           2px;
  right:         10px;
  
  overflow:      auto;
  z-index:       9001;
  
  background:    #00000000;
}

.divCoeur {
  display:       inline-block;

  position:      absolute;
  top:           2px;
  right:         50px;
  
  overflow:      auto;
  z-index:       9002;
  
  background:    #00000000;
}

.divGauche {
  display:       inline-block;

  position:      absolute;
  top:           50%;
  left:          10px;
  
  overflow:      auto;
  z-index:       9003;
  
  background:    #00000000;
}

.divDroite {
  display:       inline-block;

  position:      absolute;
  top:           50%;
  right:         10px;
  
  overflow:      auto;
  z-index:       9004;
  
  background:    #00000000;
}

.spanCroix {
  color:         #FFFFFFFF;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     100%;
  font-weight:   bold;
}

.spanCroix:hover {
  cursor: pointer;
  color:         #FF0000FF;
}

.titre{
    font-weight: 700;

}
