.root {
  height:           100%; 
  width:            100%;
}

.accroche2 {
  margin-top:    8%;
  color:         #FFFFFFFF;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     35px;
  font-weight:   bold;
  background:    #0000007F;
}
