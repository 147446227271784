@import url('https://fonts.googleapis.com/css?family=Playpen%20Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow%20Condensed&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Barlow Condensed',sans-serif;
  color: #FFFFFF;
}

:root {
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgb(0, 0, 0);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;

  background: #000000;

/*  min-width: 320px; */
/*  min-height: 100vh;*/

/*  background: #242424;


  background-size: 100% 100%;

  background-image: url("https://home.lovelightfactory.com/images/fond2-sd.jpg"); */
}


input[type=date]::-webkit-calendar-picker-indicator {
 filter: invert(1);

}

.link{
  color:#000000;

}
