.root {
  height:           fit-content; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
 
  background:      #f5f5f5;

  }

.contain {
  height:          100%; 
  width:           100%;
  max-width:       950px;
  margin-left:     auto;
  margin-right:    auto;
  margin-top:      0px;
  margin-bottom:   0px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.texte_2{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   26px;
 font-weight: bold;
 padding-left: 150px; 
 padding-right: 150px; 
 box-sizing:      border-box;
}



.texte_1{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 text-wrap: wrap;
 padding-left: 150px; 
 padding-right: 150px; 
 box-sizing:      border-box;
}

.texte_1b{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 font-weight: bold;
 text-wrap: wrap;
 padding-left: 150px; 
 padding-right: 150px; 
}

.link{

  color: #000000;
}

.espace_haut{
  
    height: 50px;
}

.espace_bas{
  
    height: 50px;
}

.photos {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;  
}

.photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
  color:#000000;
  grid-column: 2;
  grid-row: 1;
}

.photo3 {
  grid-column: 3;
  grid-row: 1;
}

.image{
 width: auto;
 height: 300px;
 margin-left:5px;
 margin-right:5px;
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {

.image{
 width: auto;
 height: 290px;
}


}

@media only screen and (max-width: 460px) and (orientation: landscape) {

  .image{
    width: auto;
    height: 150px;
  }
  
  .photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
  grid-column: 1;
  grid-row: 2;
}

.photo3 {
  grid-column: 1;
  grid-row: 3;
}

.texte_2{
 padding-left: 0px; 
 padding-right: 0px; 
}


.texte_1{
 padding-left: 0px; 
 padding-right: 0px; 
}

.texte_1b{
 padding-left: 0px; 
 padding-right: 0px; 
}
}

@media only screen and (max-width: 460px) and (orientation: portrait)  {


  .contain {
    height:          100%; 
    width:           95%;
    margin-left:     auto;
    margin-right:    auto;
  }
  
  .photos {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;  
}

.photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
 color:#000000;
  grid-column: 1;
  grid-row: 2;
}

.photo3 {
  grid-column: 1;
  grid-row: 3;
}

.texte_2{
 padding-left: 0px; 
 padding-right: 0px; 
}


.texte_1{
 padding-left: 0px; 
 padding-right: 0px; 
}

.texte_1b{
 padding-left: 0px; 
 padding-right: 0px; 
}
}