.root {
}

.trigger_reset{
  height: 50px;
  width: 100%;
  font-size: 19px;
    margin-top: 30px;
  padding: 0 15px;
  font-weight: 600;
  letter-spacing: 1px;

 }

 .message_ack{
 color:#00FF00
}

.message_error{
 color:#FF0000
}
