.root {
}

.switch
 {
  width:         100%;
  margin-bottom: 3px;
  font-size:     19px;
  justify-content: left;  
    box-sizing:    border-box;

 }

.switch_nom
 {
  font-size:     70%;
  color:         #cccccc;
 // height:        70%;
  padding: 0 15px;
  margin: 0;
  margin-top: 10px;
  place-items: left;
  justify-content: left;
  text-align: left;
  align-items: left;
    box-sizing:    border-box;

}

.switch_info
 {
  font-size:     60%;
  color:         #ff0000;
 // height:        50%;
  padding: 0;
  margin: 0;
  place-items: center;
  justify-content: center;
  text-align: center;
  align-items: center;
    box-sizing:    border-box;

}

.switch_button
 {
  height:        40px;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #ffffff;
  caret-color:   #FFFF00;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.switch_button:focus
 {
  color:      #FFFF00;
  box-shadow:       0 0 5px rgba(255,255,0,.2),
              inset 0 0 5px rgba(0,255,0,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

@keyframes glow
 {
  0% {
        border-color: #FFFF00;
        box-shadow:         0 0 5px rgba(255,255,0,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #FFFF00;
        box-shadow:         0 0 20px rgba(255,255,0,.6),
                      inset 0 0 10px rgba(255,255,0,.4);
       }
}

