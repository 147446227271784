.root {
  }

.sticky {
  position:              fixed;
  top:                   0;
  left:                  0;
  z-index:               4000;
  place-items:           center;
  justify-content:       center;
  background-color:      black;
  width:                 100%;
  height:                70px;
  text-align:            center;
  
  display:               grid;
  grid-template-columns: 25% 25% 50%;
  grid-template-rows:    70px;
  vertical-align:        middle;
  box-sizing:            border-box;
 }
 
.left {
  grid-column:  1;
  grid-row:     1;
  text-align:   left;
  place-items:  left;
  margin-left:  0;
  margin-right: auto;
  }

.center {
  grid-column:  2;
  grid-row:     1;
  text-align:   center;
  place-items:  center;
  }

.right {
  grid-column:  3;
  grid-row:     1;
  align:        right;
  text-align:   right;
  margin-left:  auto;
  margin-right: 15px;
  place-items:  right;
  font-size:    20px;

  }
 

.button {
  height:           30px;
  width:            fit-content;
  font-size:        12px;
  color:            white;
  background-color: black;
  padding:          0 5px;
  margin-top:       5px;	
  margin-right:     30px;
  border:           0px solid #FFFFFF;
  cursor:           pointer;
  font-family:      'Barlow Condensed',sans-serif;
  letter-spacing:   1px;
  font-weight:      600;
    text-align:   justify;
  }


.button:hover {
  color:            black;
  background-color: white;
  }

/*
.button2 {
  height:           30px;
  width:            fit-content;
  font-size:        12px;
  color:            red;
  font-family:      'Barlow Condensed',sans-serif;
  letter-spacing:   1px;
  font-weight:      600;
}
*/

.choix {
  color:            white;
  text-decoration: none;
}

.choix:hover {
  color:            black;
}

.logo{
  margin-top:4px;
  margin-left:4px;
  height:64px;
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {

  .sticky {
    grid-template-columns: 20% 50% 30%;
  }

}


