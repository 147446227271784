.root {
  min-height:       fit-content;
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;

  background:      #000000;

}

.contain {
  height:          100%; 
  width:           40%;
  margin:          0;
  margin-left:     auto;
  margin-right:    auto;

  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

  .espace_haut{
  
    height: 40px;
  }

  .espace_haut_bis{
  
    height: 40px;
  }

   .espace_bas{

    height: 40px;
  }

.date{
 color:       #FFFFFF;
 text-align:  left;
 font-size:   24px;
}

.texte{
 color:       #FFFFFF;
 text-align:  center;
 font-size:   32px;
 font-weight: bold;
}

.titre{
 color:       #FFFFFF;
 text-align:  center;
 font-size:   32px;
 font-weight: bold;
}

@media only screen and (max-width: 460px) {


  .contain {
    width:     95%;
  }
  
  .date{
    font-size: 20px;
  }

  .texte{
    font-size: 28px;  
  }

  .titre{
    font-size: 28px;
  }

}
