.root {
}

.photo_nom{
  font-size:       100%;
  color:           #ffffff;
  padding:         0 15px;
  margin:          0;
  margin-top:      10px;
  place-items:     left;
  justify-content: left;
  text-align:      left;
  align-items:     left;
  box-sizing:      border-box;
}

.bloc {
  height:                fit-content;
  width:                 100%;
  outline:               none;
  font-size:             100%;
  color:                 #ffffff;
  border-radius:         5px;
  border:                2px solid #444;
  caret-color:           #FFFF00;
  background:            linear-gradient(#333,#222);
  padding:               10px;
  margin:                0;
  margin-bottom:         10px;
  box-sizing:            border-box;
  
  display:               grid;
  grid-template-columns: 100%;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.bloc_ligne_photo {
  grid-column:     1;
  grid-row:        1;
  place-items:     center;
  justify-content: center;
    text-align: center;

}

.bloc_ligne_info {
  grid-column: 1;
  grid-row: 2;
  place-items: center;
  justify-content: center;
}

.bloc_ligne_certif {
  grid-column: 1;
  grid-row: 3;
  place-items: center;
  justify-content: center;
  text-align:center;
}

.bloc_ligne_boutons {
  grid-column: 1;
  grid-row: 4;
  place-items: center;
  justify-content: center;
  text-align:center;
}

.grid {
  display:               grid;
  grid-template-columns: 18% 1% 24 2% 55%;
  grid-gap:              0px;
  grid-template-rows:    33% 33% 33% 1%;
}

.name_nom {
  grid-column: 1;
  grid-row: 1;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
}

.name_data {
  grid-column: 3;
  grid-row: 1;
}

.cameraBrand_nom {
  grid-column: 1;
  grid-row: 2;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
  
}

.cameraBrand_data {
  grid-column: 3;
  grid-row: 2;
}

.cameraModel_nom {
  grid-column: 1;
  grid-row: 3;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
}

.cameraModel_data {
  grid-column: 3;
  grid-row: 3;
}

.description {
  grid-column: 5;
  grid-row: 1/5;
}


.texte_1{
 width:      100%;
 text-align: justify;
 font-size:      26px;
 font-weight: bold;
}

.texte_2{
 width:      100%;
 text-align: justify;
 font-size:      18px;
 margin-top: 10px;
 margin-bottom: 20px;
}


.required {
  font-size:      0.8em;  
  vertical-align: super;  
  color:          #FF0000;
}

@media only screen and (max-width: 460px) {

.grid {
  display:               grid;
  grid-template-columns: 49% 1% 50%;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.name_nom {
  grid-column: 1;
  grid-row: 1;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
		text-wrap: wrap;

}

.name_data {
  grid-column: 3;
  grid-row: 1;
}

.cameraBrand_nom {
  grid-column: 1;
  grid-row: 2;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
		text-wrap: wrap;

  
}

.cameraBrand_data {
  grid-column: 3;
  grid-row: 2;
}

.cameraModel_nom {
  grid-column: 1;
  grid-row: 3;
    font-size:     13.3px;
  color:         #969696;
    text-align:right;
		text-wrap: wrap;

}

.cameraModel_data {
  grid-column: 3;
  grid-row: 3;
}

.description {
  grid-column: 1/4;
  grid-row: 4;
}

}





.vignette {

  border-radius: 5px 5px 5px 5px;
  overflow-wrap: break-word;

   width: 100%;
   place-items: center;
  justify-content: center;
}

.vignette:hover{
  cursor: pointer;
  color: #FFFF00;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}

.progress{
  border: 1px solid #444;
  width: 100%;
  margin: auto;
  text-align: center;
  place-items: center;
  justify-content: center;

}

.placeholder_photo{
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  place-items: center;
  justify-content: center;
  align-items:     center;
  text-align:      center;

}


.little_trigger{

  border-radius: 5px!important;
  cursor: pointer;
  outline: none;
  color: #FFFFFF;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #444;
  caret-color: #FFFF00;
  background: linear-gradient(#333,#222);
  margin: 5px 5px 5px 5px;
  padding:  0 10px;

  width:fit-content;
  height:30px;
    box-sizing:      border-box;

}

.little_trigger:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
  color: #7F7F7F;
}

.little_trigger:hover{
  color: #FFFF00;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}

.little_trigger:disabled:hover{
  color: #7F7F7F;
  border: 1px solid #7F7F7F;
  box-shadow: 0 0 5px rgba(127,127,127,.3),
              0 0 10px rgba(127,127,127,.2),
              0 0 15px rgba(127,127,127,.1),
              0 2px 0 black;
}


.little_trigger_save{
  border-radius: 5px!important;
  cursor: pointer;
  outline: none;
  color: #00FF00;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #040;
  caret-color: #FFFF00;
  background: linear-gradient(#333,#222);
  margin: 5px 5px 5px 5px;

  width:fit-content;
  height:30px
}

.little_trigger_save:hover{
  color: #00FF00;
  border: 1px solid #00FF00;
  box-shadow: 0 0 5px rgba(0,255,0,.3),
              0 0 10px rgba(0,255,0,.2),
              0 0 15px rgba(0,255,0,.1),
              0 2px 0 black;
}

.commentaires{
height: 193px
}

.errorMessage{
  color: #FF0000;
}

.progressGauge{
 width: fit-content;
 text-align: center;
 margin:auto;
}



.blinking {
  animation: blink 1s infinite alternate; /* Animation de clignotement */
}

@keyframes blink {
  from {
    color: yellow;
  }
  to {
    color: green;
  }
}

.field {
 display:inline;
 flex-grow: 0;
 width:100%

}
