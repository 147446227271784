.root {
}

.trigger{

  border-radius: 5px!important;
  cursor: pointer;
  outline: none;
  color: #FFFFFF;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid #444;
  caret-color: #FFFF00;
  background: linear-gradient(#333,#222);
}

.trigger_icone{
  width:30px;
  height:30px
}

.trigger:disabled{
  cursor: not-allowed;
  pointer-events: all !important;
}

.trigger:hover{
  color: #FFFF00;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}

.trigger:disabled:hover{
  color: #FFFFFF;
  border: 1px solid #7F7F7F;
  box-shadow: 0 0 5px rgba(127,127,127,.3),
              0 0 10px rgba(127,127,127,.2),
              0 0 15px rgba(127,127,127,.1),
              0 2px 0 black;
}

