.root {

  margin-top:       70px;

  height:           1000px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
/*  place-items:      center; */
  text-align:       center;
  box-sizing:      border-box;
  
}

.contain {
  height:          100%; 
  width:           90%;
  margin:          auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
/*  place-items:      center; */
  text-align:      center;
  box-sizing:      border-box;

}

.espace_haut{
  
    display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;

}

.gauche{
    grid-column: 1;
  grid-row: 1;
  align: left;
  text-align: center;
  margin-left: 5px;
  place-items: center;

}


.droite{
    grid-column: 2;
  grid-row: 1;
  align: right;
  text-align: justify;
  margin-right: 5px;
  place-items: left;
  font-size: 20px;

}


.compteur{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

}

.text_bas{
  margin-top:  0.3%;
  margin-bottom:  2%;
  font-size:  20px;
}


.espace_bas{
  
  height: 10px;
}

.texte{
  padding:         10px;
  background:      #0000007F;

}

.texte_gras {
  font-weight: 700;
}

.texte_votez{

  font-size: 30px;

}

.texte_avant_cloture{

  font-size: 30px;

}


.custom_overlay_caption {
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 240px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 2px;
  font-size: 90%;
}

.minilogo {
  height: 30px;
}

.logo_ll{
  width:520px;
}

.flip_clock {
  --fcc-flip-duration:      0.5s; /* transition duration when flip card */
  --fcc-digit-block-width:  30px; /* digit card's width */
  --fcc-digit-block-height: 50px; /* digit card's height */
  --fcc-digit-font-size:    180%; /* font size of digit */
  --fcc-digit-color:        white; /* color of digit */
  --fcc-background:         black; /* digit card's background */
  --fcc-label-color:        #ffffff; /* time label's color */
  --fcc-divider-color:      #ffffff66; /* divider's color */
}


@media only screen and (max-width: 1920px) {
  .root {
    height:           1080px; 

  }
 }

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .root {
    height:           1024px; 

  }
 }

@media only screen and (max-width: 1440px) and (orientation: portrait) {
  .root {

    height:        1170px;


  }
 }
 
@media only screen and (max-width: 460px) {
  .root {

    margin-top:   0px;

    height:        800px;


  }

  .espace_haut{

    width:100%;

    display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

}

.logo_ll{
    width:100%
}


.gauche{
    width:100%;

  grid-column: 1;
  grid-row: 1;
  align: left;
  text-align: center;
  margin-left: 5px;
  place-items: center;

}


.droite{
    grid-column: 1;
  grid-row: 2;
  align: right;
  text-align: justify;
  margin-right: 5px;
  place-items: left;
  font-size: 20px;

}

.flip_clock {
  --fcc-digit-block-width:  20px; /* digit card's width */
  --fcc-digit-block-height: 32px; /* digit card's height */
  --fcc-label-font-size:    13px; /* time label's color */
}

.texte_avant_cloture{

  font-size: 18px;

}


 }

.coche {

  width: 20px; 
  height: 20px; 
}