.root {
}

.field {
 /* display: flex; */
  width:           100%;
  margin-bottom:   3px;
  font-size:       19px;
  justify-content: left;  
  color:  #000000;
}

.field_nom  {
  width:        150px;
  font-size:     70%;
  color:         #969696;
 // height:        70%;
  padding: 0 5px;
  margin: 0;
  place-items: right;
  justify-content: right;
  text-align: right;
  align-items: right;
}


.field_info {
clear: both;
width:           100%;
  font-size:       60%;
  color:           #ff0000;
  padding:         0;
  margin:          0;
  place-items:     center;
  justify-content: center;
  text-align:      center;
  align-items:     center;
}

.field_bloc_input{
  width:           100%;
  height:          fit-content;
  display:         flex;
  place-items:     left;
  justify-content: left;
  text-align:      left;
  align-items:     left;
}

.field_input
 {
  height:        40px;
  width:         100%;
  outline:       none;
 /* font-size:     100%; */
  color:         #000000;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #ffffff; /* linear-gradient(#333,#222); */
 /* padding:       0 10px;
  padding:       0;*/
  box-sizing:    border-box;
}

input::-webkit-calendar-picker-indicator {
    opacity: 100;
}

.field_input:focus
 {
  color:      #000000;
  box-shadow:       0 0 5px rgba(128,0,128,.2),
              inset 0 0 5px rgba(88,26,129,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

.field_input_read_only
 {
  height:        40px;
  width:         100%; 
  outline:       none;
 /* font-size:     100%;*/
  color:         #000000;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #f5f5f5; /* linear-gradient(#333,#222); */
 /* margin:        0;
  padding:       0 10px;*/
  box-sizing:    border-box;
}


.span_eye {
  color: #FFFFFF;
  height:        38px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
    cursor: pointer;

  }

@keyframes glow
 {
  0% {
        border-color: #581A81;
        box-shadow:         0 0 5px rgba(88,26,129,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #581A81;
        box-shadow:         0 0 20px rgba(88,26,129,.6),
                      inset 0 0 10px rgba(88,26,129,.4);
       }
}


.required {
  font-size:      0.8em;  
  vertical-align: super;  
  color:          #FF0000;
}

.redIcon {
  color: #FF0000;
}

.slash{
text-decoration:line-through;
}