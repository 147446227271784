.root {

  display:         flex; 
  justify-content: center; 
  align-items:     center;
  flex-wrap:       wrap;
  
  margin:          auto;

  width:           95%;

  align:           center;
  place-items:     center;
  text-align:      center;

  box-sizing:      border-box;

  font-size:       40px;
}

.photo_overlay {
  margin:    0;
  position:  relative; 
}

.logo_challenge {
  margin:        0;
  margin-left:   auto;
  margin-right:  auto;
  width:         100%;
}

.text_overlay {
  position:   absolute;
  top:        72%;
  left:       50%;
  transform:  translate(-50%, -50%);
  width:      fit-content;
  color:      white; 
  font-size:  50%; 
  text-align: center;
}

.flip_clock {
  --fcc-flip-duration:      0.5s; /* transition duration when flip card */
  --fcc-digit-block-width:  30px; /* digit card's width */
  --fcc-digit-block-height: 50px; /* digit card's height */
  --fcc-digit-font-size:    180%; /* font size of digit */
  --fcc-digit-color:        white; /* color of digit */
  --fcc-background:         black; /* digit card's background */
  --fcc-label-color:        #ffffff; /* time label's color */
  --fcc-divider-color:      #ffffff66; /* divider's color */
}

.text_haut{
  margin-top:  0;
  margin-bottom:  0.5%;
  font-size:  150%;  
  font-weight: bold;
}

.text_bas{
  margin-top:  0.3%;
  margin-bottom:  2%;
  font-size:  100%;
}

.trigger_registration{

  height:         fit-content;
  width:          fit-content;
  font-size:      200%;
  padding:        0 15px;
  font-weight:    600;
  letter-spacing: 1px;
  color: #000000;
  
  border-radius: 10px!important;
  cursor: pointer;
  outline: none;

  border: 1px solid #444;
  caret-color: #000000;
  background: linear-gradient(#FF0,#AA0);
}

.trigger:hover{
  color: #DDDDDD;
  border: 1px solid #FFFF00;
  box-shadow: 0 0 5px rgba(255,255,0,.3),
              0 0 10px rgba(255,255,0,.2),
              0 0 15px rgba(255,255,0,.1),
              0 2px 0 black;
}



@media only screen and (max-width: 460px) {


.root {
    font-size:       6vw;
}

.text_haut{
  font-size:  150%;  
}

.flip_clock {
  --fcc-digit-block-width:  20px; /* digit card's width */
  --fcc-digit-block-height: 32px; /* digit card's height */
  --fcc-label-font-size:    13px; /* time label's color */
}


}
