.root {
  height:                fit-content;
  width:                 100%;
  outline:               none;
  font-size:             100%;
  color:                 #ffffff;
  border-radius:         5px;
  border:                2px solid #444;
  caret-color:           #FFFF00;
  background:            linear-gradient(#333,#222);
  padding:               0 10px;
  margin:                0;
  box-sizing:            border-box;

  display:               grid;
  grid-template-columns: 49% 2% 49% auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.grid_password_nom{
  grid-column: 1;
  grid-row: 1;
}

.grid_password_data{
  grid-column: 3;
  grid-row: 1;
}

.grid_password2_nom{
  grid-column: 1;
  grid-row: 2;
}

.grid_password2_data{
  grid-column: 3;
  grid-row: 2;
}

.grid_button{
  grid-column: 1/4;
  grid-row: 3;
}


.big_trigger{
  height:         50px;
  width:          fit-content;
  font-size:      19px;
  margin:         15px;
  padding:        0 15px;
  font-weight:    600;
  letter-spacing: 1px;
}
 
 
 

.nom{
  font-size:     16px;
  color:         #969696;
  padding: 0 5px;
  margin: 0;
  place-items: right;
  justify-content: right;
  text-align: right;
  align-items: right;
  vertical-align: middle
}


.field_bloc_input{
  flex-grow:     1;
  height:          fit-content;
  display:         flex;
  place-items:     left;
  justify-content: left;
  text-align:      left;
  align-items:     left;
}

.field_input
 {
  height:        40px;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #ffffff; /* linear-gradient(#333,#222); */
  padding:       0 10px;
  padding:       0;
  box-sizing:    border-box;
}

.field_input:focus
 {
  color:      #000000;
  box-shadow:       0 0 5px rgba(128,0,128,.2),
              inset 0 0 5px rgba(88,26,129,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

.field_input_read_only
 {
  height:        40px;
  flex-grow:     1;
  border:        2px solid #242424;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
  caret-color:   #000000;
  background:    #f5f5f5; /* linear-gradient(#333,#222); */
  margin:        0;
  padding:       0 10px;
  box-sizing:    border-box;
}


.span_eye {
  height:        38px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

@keyframes glow
 {
  0% {
        border-color: #581A81;
        box-shadow:         0 0 5px rgba(88,26,129,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #581A81;
        box-shadow:         0 0 20px rgba(88,26,129,.6),
                      inset 0 0 10px rgba(88,26,129,.4);
       }
}


.required {
  font-size:      0.8em;  
  vertical-align: super;  
  color:          #FF0000;
}

.redIcon {
  color: #FF0000;
}



.espace_haut{
  
  height: 70px;
}

.espace_bas{
  
  height: 45px;
}

.texte_1{
 width:      100%;
 text-align: justify;
 font-size:      18px;
 font-weight: bold;
 color:  #000000;
}

.texte_2{
 width:      100%;
 text-align: justify;
 font-size:      16px;
 color:  #000000;
}

.text_rgpd{
 width:      100%;
 text-align: justify;
 font-size:      16px;
 color:  #000000;
}

.div_vignette {
  height:          fit-content; 
  width:           100%;
  margin:          0;
  padding:         0;
  justify-content: left;
  align-items:     left; 
  align:           left;
  place-items:     left;
  text-align:      left;
  box-sizing:      border-box;
}

.vignette{
 width: 256px;
 margin-left: 70px;
 margin-right: auto;
 
}

.link{

  color: #000000;
}



@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {
}

@media only screen and (max-width: 460px) and (orientation: portrait) {


.root{
  display:               grid;
  grid-template-columns: 49% 2% 49%;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.grid_password_nom{
  grid-column: 1;
  grid-row: 1;
}

.grid_password_data{
  grid-column: 3;
  grid-row: 1;
}

.grid_password2_nom{
  grid-column: 1;
  grid-row: 2;
}

.grid_password2_data{
  grid-column: 3;
  grid-row: 2;
}

.grid_button{
  grid-column: 1/4;
  grid-row: 3;
}

}

 
 
 