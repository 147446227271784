.root {

  margin-top:       70px;

  height:           1440px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
  
  background:      #000000;

  background-size:  2560px; 
  background-image: url("https://lovelightchallenge.com/images/fond-welcome-2560x1440.jpg");
}

.contain {

  margin-top:       0px;
  max-width:       800px; 
  margin-left:     500px;
  margin-right:    auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.espace{
  
  height: 90px;
}


.album {
  margin: auto;
  width:  40%;
}

.logo_partenaire {
  width: 100%;
}

.text {
  margin-left:   auto;
  margin-right:  auto;
  width:         fit-content;
  color:         white;
  background:    #0000007F;
  }

.blocDroite
 {
  display:       inline-block;
  position:      absolute;

  top:           15%; 
  left:          57%; 
  height:        fit-content;
  width:         31%;
  
  overflow:      auto;
  z-index:       200;

  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
 }

.accroche {
  color:         #FFFFFFFF;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     40px;
  font-weight:   bold;
}

.accroche2 {
  margin-top:    10%;
  color:         #FFFFFFFF;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     40px;
  font-weight:   bold;
  background:    #0000007F;
  cursor:        pointer;
}

.partenaires {
  margin-top:    10%;
  color:         #FFFFFFFF;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     20px;
  font-weight:   bold;
}

@media only screen and (max-width: 1920px) {

  .root {
    margin-top:       70px;
    height:           900px; 
    background-size:  1920px;
    background-image: url("https://lovelightchallenge.com/images/fond-welcome-2560x1440.jpg"); 
  }

  .contain {

    margin-top:       0px;
    max-width:       750px; 
    margin-left:     150px;
  }

  .blocDroite {
   width:         40%;
  }

.espace{
  
  height: 10px;
}

.album {
  width:  37%;
}

.partenaires {
  margin-top:    9%;
 }
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {

  .root {
    margin-top:       70px;
    height:           1024px; 
    background-size:  1440px;
    background-image: url("https://lovelightchallenge.com/images/fond-welcome-1368x1024-L.jpg"); 
  }

  .contain {
    margin-top:       0px;
    max-width:     600px; 
    margin-left:   40px;
  }

  .espace{
  
    height:        124px;
  }

  .blocDroite {
    top:           20%; 
    left:          55%; 
    width:         40%;
  }

  .accroche2 {
    margin-top:    15%;
    font-size:     28px;   
  }
  
  .partenaires {
    margin-top:    10%;
  }
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {

  .root {
    margin-top:       70px;

    height:        1170px;

    background-size:  1024px;
    background-image: url("https://lovelightchallenge.com/images/fond-welcome-1368x1024-P.jpg"); 
  }

  .contain {
    margin-top:       0px;
    max-width:     65%; 
    margin-left:   auto;
  }
  
  .blocDroite {
    top:           55%; 
    left:          10%; 
    width:         80%;
  }
  
  .accroche {
    margin-top:    0;
    font-size:     36px;  
  }

  .accroche2 {
    margin-top:    5%;
    font-size:     28px;  
  }
  
  .partenaires {
    margin-top:    5%;
   }
}

@media only screen and (max-width: 460px) and (orientation: portrait) {

  .root {

    margin-top:   0px;

    height:        800px;

    background-size:  100%;
    background-image: url("https://lovelightchallenge.com/images/fond-welcome-464x934-P.jpg"); 
  }

  .contain {
    margin-top:       0px;
    max-width:     90%; 
    margin-left:   auto;
  }
  
  .espace{
  
    height:        10px;
  }
  
  .blocDroite {
    top:           55%; 
    left:          10%; 
    width:         80%;
  }
  
  .accroche {
    color:         #000000;
    margin-top:    0;
    font-size:     22px;  
  }

  .accroche2 {
    margin-top:    5%;
    font-size:     14px;  
  }
  
  .partenaires {
    margin-top:    7%;
   }
   
   .text {
     background:    #00000000;
   }
   
   
   .album {
    margin: auto;
    width:  35%;
   }
}
