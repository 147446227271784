.root {
  height:           fit-content; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
 
  background:      #000000;

  }

.contain {
  height:          fit-content; 
  width:           fit-content;
  margin-left:     auto;
  margin-right:    auto;
  margin-top:      0px;
  margin-bottom:   0px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.texte_2{
 color:       #FFFFFFFF;
 width:       100%;
 text-align:  center;
 font-size:   36px;
 font-weight: bold;
 padding-left: 150px; 
 padding-right: 150px; 
 box-sizing:      border-box;
}

.accroche3 {
  margin-top:    8%;
  color:         #00000000;
  text-align:    center;
  text-wrap:     wrap;
  font-size:     32px;
  font-weight:   bold;
  background:    #0000007F;
}


.texte_1{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 text-wrap: wrap;
 padding-left: 150px; 
 padding-right: 150px; 
 box-sizing:      border-box;
}

.texte_1b{
 color:       #000000;
 width:       100%;
 text-align:  justify;
 font-size:   18px;
 font-weight: bold;
 text-wrap: wrap;
 padding-left: 150px; 
 padding-right: 150px; 
}

.link{

  color: #000000;
}

.espace_haut{
  
    height: 50px;
}

.espace_bas{
  
    height: 50px;
}

.photos {
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;  
}

.photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
  grid-column: 2;
  grid-row: 1;
}

.photo3 {
  grid-column: 3;
  grid-row: 1;
}

.photo4 {
  grid-column: 4;
  grid-row: 1;
}

.photo5 {
  grid-column: 5;
  grid-row: 1;
}

.photo6 {
  grid-column: 1;
  grid-row: 2;
}

.photo7 {
  grid-column: 2;
  grid-row: 2;
}

.photo8 {
  grid-column: 3;
  grid-row: 2;
}

.photo9 {
  grid-column: 4;
  grid-row: 2;
}

.photo10 {
  grid-column: 5;
  grid-row: 2;
}

.photo11 {
  grid-column: 1;
  grid-row: 3;
}

.photo12 {
  grid-column: 2;
  grid-row: 3;
}

.photo13 {
  grid-column: 3;
  grid-row: 3;
}

.photo14 {
  grid-column: 4;
  grid-row: 3;
}

.photo15 {
  grid-column: 5;
  grid-row: 3;
}

.image{
 width: auto;
 height: 400px;
 margin-left:5px;
 margin-right:5px;
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {

.image{
 width: auto;
 height: 400px;
}


}

@media only screen and (max-width: 460px) and (orientation: landscape) {

  .image{
    width: auto;
    height: 150px;
  }
  
.photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
  grid-column: 1;
  grid-row: 2;
}

.photo3 {
  grid-column: 1;
  grid-row: 3;
}


.photo4 {
  grid-column: 1;
  grid-row: 4;
}

.photo5 {
  grid-column: 1;
  grid-row: 5;
}

.photo6 {
  grid-column: 1;
  grid-row: 6;
}

.photo7 {
  grid-column: 1;
  grid-row: 7;
}

.photo8 {
  grid-column: 1;
  grid-row: 8;
}

.photo9 {
  grid-column: 1;
  grid-row: 9;
}

.photo10 {
  grid-column: 1;
  grid-row: 10;
}

.photo11 {
  grid-column: 1;
  grid-row: 11;
}

.photo12 {
  grid-column: 1;
  grid-row: 12;
}

.photo13 {
  grid-column: 1;
  grid-row: 13;
}

.photo14 {
  grid-column: 1;
  grid-row: 14;
}

.photo15 {
  grid-column: 1;
  grid-row: 15;
}



.texte_2{
 padding-left: 0px; 
 padding-right: 0px; 
}


.texte_1{
 padding-left: 0px; 
 padding-right: 0px; 
}

.texte_1b{
 padding-left: 0px; 
 padding-right: 0px; 
}
}

@media only screen and (max-width: 460px) and (orientation: portrait)  {


  .contain {
    height:          100%; 
    width:           95%;
    margin-left:     auto;
    margin-right:    auto;
  }
  
  .photos {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;  
}

.photo1 {
  grid-column: 1;
  grid-row: 1;
}

.photo2 {
 color:#000000;
  grid-column: 1;
  grid-row: 2;
}

.photo3 {
  grid-column: 1;
  grid-row: 3;
}


.photo4 {
  grid-column: 1;
  grid-row: 4;
}

.photo5 {
  grid-column: 1;
  grid-row: 5;
}

.photo6 {
  grid-column: 1;
  grid-row: 6;
}

.photo7 {
  grid-column: 1;
  grid-row: 7;
}

.photo8 {
  grid-column: 1;
  grid-row: 8;
}

.photo9 {
  grid-column: 1;
  grid-row: 9;
}

.photo10 {
  grid-column: 1;
  grid-row: 10;
}

.photo11 {
  grid-column: 1;
  grid-row: 11;
}

.photo12 {
  grid-column: 1;
  grid-row: 12;
}

.photo13 {
  grid-column: 1;
  grid-row: 13;
}

.photo14 {
  grid-column: 1;
  grid-row: 14;
}

.photo15 {
  grid-column: 1;
  grid-row: 15;
}





.texte_2{
 padding-left: 0px; 
 padding-right: 0px; 
}


.texte_1{
 padding-left: 0px; 
 padding-right: 0px; 
}

.texte_1b{
 padding-left: 0px; 
 padding-right: 0px; 
}
}