.root {

  margin-top:       70px;

  height:           1440px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
  
  background-size:  2560px; 
  background-image: url("https://home.lovelightfactory.com/images/fond-welcome-2560x1440.jpg");
}

.contain {
  height:          fit-content; 
  width:           100%;
  max-width:       400px; 
  margin:          auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.informations {

  width:           100%;

}

.titre {
  width:  fit-content;
  margin: auto;
  font-size: 200%;
}

.sous_titre {
  width:  fit-content;
  margin: auto;
  font-size: 170%;
  color:#00FF00;

}

.trigger_login{
  height:         50px;
  width:          fit-content;
  font-size:      19px;
  margin:         auto;
  margin-top:     30px;
  padding:        0 15px;
  font-weight:    600;
  letter-spacing: 1px;
}
 
.message_ack{
  color:#00FF00;
  text-align:    center;
}

.message_error{
  color:#FF0000;
  text-align:    center;
}
 
.forgotten_password{
  text-align: right;
}


.espace_haut{
  
  height: 200px;
}

.espace_bas{
  
  height: 45px;
}



.vignette {

  overflow-wrap: break-word;

   width: 100%;
   place-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1920px) {
  .root {
    height:           1080px; 
    background-size:  1920px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1920-1080.jpg"); 
  }
  
  .espace_haut{
  
    height: 10px;
  }

 }

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .root {
    height:           1024px; 
    background-size:  1440px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-L.jpg"); 
  }

  .espace_haut{
  
    height: 100px;
  }
 }

@media only screen and (max-width: 1440px) and (orientation: portrait) {
  .root {

    height:        1170px;

    background-size:  1024px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-P.jpg"); 
  }

  .espace_haut{
  
    height: 200px;
  }
 }
 
@media only screen and (max-width: 460px) {
  .root {

    margin-top:   0px;

    height:        800px;

    background-size:  100%;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-464x934-P.jpg"); 
  }
   
   .vignette {
      width: 75%;
 }

  .espace_haut{
  
    height: 35px;
  }
  
  .contain {
    width: 90%;
	}
	
.trigger_login{
  height:         40px;
  font-size:      17px;
  margin-top:     10px;
  padding:        0 15px;
  font-weight:    600;
  letter-spacing: 1px;
}
	
}
