.root {
  height:           fit-contain; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;
  
  background:      #000000;
}

.contain {
  height:          100%; 
  width:           100%;
  max-width:       800px;
  margin-left:     auto;
  margin-right:    auto;
  margin-top:      0px;
  margin-bottom:   28px;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.titre{ 
 margin-left: auto;
 margin-right: auto;
 padding-top: 30px;
 color:       #FFFFFF;
 width:       100%;
 text-align:  center;
 font-size:   26px;
 font-weight: bold;
 box-sizing:  border-box;
 text-wrap:   wrap;
}

.texte{ 
 margin-left: auto;
 margin-right: auto;
 padding-bottom: 30px;
 color:       #FFFFFF;
 width:       100%;
 text-align:  center;
 font-size:   26px;
 font-weight: bold;
 box-sizing:  border-box;
 text-wrap:   wrap;
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1440px) and (orientation: landscape) {
}

@media only screen and (max-width: 1440px) and (orientation: portrait) {
}

@media only screen and (max-width: 460px) and (orientation: portrait) {


  .contain {
    padding-left: 15px;
    padding-right: 15px;
  }

  .espace_haut{
    padding-top: 15px;
    height: 25px;
  }
}
