.root {
}

.textarea
 {
  display: flex;
  width:         100%;
  height:         fit-content;
  margin-bottom: 0px;
  font-size:     19px;
  justify-content: left;  
 }

.textarea_nom
 {
  width:        150px;
  font-size:     70%;
  color:         #969696;
 // height:        70%;
  padding: 0 5px;
  margin: 0;
  place-items: right;
  justify-content: right;
  text-align: right;
  align-items: right;
}

.textarea_info
 {
  font-size:     60%;
  color:         #ff0000;
 // height:        50%;
  padding: 0;
  margin: 0;
  place-items: center;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.textarea_input
 {
  height:        120px;
  width:         100%;
  outline:       none;
   font-size:     100%;
  color:         #000000;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #ffffff; /* linear-gradient(#333,#222); */
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 0;
  resize: none;
}

.textarea_input_read_only
 {
  height:        120px;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #000000;
  border-radius: 5px;
  border:        2px solid #FFFFFF;
  caret-color:   #000000;
  background:    #ffffff; /* linear-gradient(#333,#222); */
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 0;
  resize: none;
}

.textarea_input:focus
 {
  color:      #000000;
  box-shadow:       0 0 5px rgba(128,0,128,.2),
              inset 0 0 5px rgba(88,26,129,.1);
  animation:  glow .8s ease-out infinite alternate;  // On utilise l'animation décrite ci-dessous
}

@keyframes glow
 {
  0% {
        border-color: #581A81;
        box-shadow:         0 0 5px rgba(88,26,129,.2),
                      inset 0 0 5px rgba(0,0,0,.1);
       }
  100% {
        border-color: #581A81;
        box-shadow:         0 0 20px rgba(88,26,129,.6),
                      inset 0 0 10px rgba(88,26,129,.4);
       }
}


.sd_container {
  position: relative;
  float: left;
}

.sd {
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.open_button {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open_button button {
  border: none;
  background: transparent;
}
