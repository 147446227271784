.root {
}

.bloc
 {
  height:        fit-content;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
/*  border:        2px solid #444; */
  caret-color:   #FFFF00;
/*  background:    linear-gradient(#333,#222); */
  padding:       0 10px;
  margin:        auto;
  box-sizing:    border-box;

  
  place-items:     center;
  justify-content: center;
  align-items:     center;
}



.texte_1{
 width:      100%;
 text-align: justify;
 font-size:      26px;
 font-weight: bold;
}

.texte_2{
 width:      100%;
 text-align: justify;
 font-size:      18px;
 margin-top: 10px;
 margin-bottom: 20px;
}
