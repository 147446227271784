.root {
}

.window {
  display:       inline-block;
  font-size:     1em;
  width:         600px;
  position:      fixed;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  margin:        auto;
  height:        fit-content;
  padding:       10px;
  overflow:      auto;
  z-index:       700;
  border-radius: 5px!important;

  outline:       none;
  color:         #868686;
  border:        1px solid #444;
  caret-color:   #FF00FF;
  background:    linear-gradient(#333,#222);
  
  text-align:    center
}

.bloc{
 height:350px;
}

.link{

  color: #FFFFFF;
}

@media only screen and (max-width: 430px) {
 .window {
     width:         80%;
	   font-size:  12px;
  }
 .bloc{
 height:400px;
 } 
}

.trigger_window{ 
  height:     30px;
  width:      fit-content;
  font-size:  12px;
  padding:    0 5px;
  margin: 5px;	
}

.buttons{ 
  text-align : center;	
}

.text_rgpd{
 width:      100%;
 text-align: justify
}

