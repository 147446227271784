.root {
  height:          100vh; 
  width:           100%;

  background:      #000000;
  color:           #FFFFFF;
}

.back_button {

  position: fixed;
  top:      20px;
  left:     20px;
  z-index:  100;
  color:    #FFFFFF;
  
  padding: 5px;
  
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou */

}

.playlist_button {

  position: fixed;
  bottom:      20px;
  left:     20px;
  z-index:  100;
  color:    #FFFFFF;

  padding: 5px;
  
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou */
}

.playlist_icon {

  width: 40px;
  height: auto;
}



.layer {
  
  position: fixed;
  top:      0px;
  left:     0px;
  width: 100%;
  height: 100%;
  z-index:  99;
  color:    #FF00007F;
}

.frame {


}

.tv_test_pattern {

  width:         auto; 
  height:        auto;
  max-width:     90vw; 
  max-height:    90vh; 
  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);
}

.title_banner {

  position: fixed;
  bottom:      20px;
  right:     20px;
  z-index:  101;

  padding:   10px;
  font-size: 36px;
  font-weight: bold;
  background: #FFFF00;
  color:   #000000;
}

.title_italic {

  font-style: italic;
}


@media only screen and (max-width: 850px) {

  .title_banner {
    font-size: 24px;
  }
}


.glass_1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 61;
  background: rgba(0, 0, 0, 0.7);
}

.glass_2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 61;
  background: rgba(0, 0, 0, 0);
}

.play_button {
  width: auto;
  height: auto;
  max-width: 50vw;
  max-height: 50vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.waiting_message {

  position: fixed;
  width: auto;
  height: auto;
  max-width: 50vw;
  max-height: 50vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:  100;
  color:    #FFFFFF;

  padding: 5px;

  border: 1px solid #FFFFFF;
  border-radius: 10px;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou */

text-wrap: wrap;
}

