.root {

  margin-top:       70px;

  height:           1000px; 
  width:            100%;
  justify-content:  center;
  align-items:      center; 
  align:            center;
  place-items:      center;
  text-align:       center;
  box-sizing:       border-box;

  background:      #000000;
  background-repeat: no-repeat;
  background-size:  2560px; 
  background-image: url("https://home.lovelightfactory.com/images/fond-welcome-2560x1440.jpg");
}

.contain {
  height:          100%; 
  width:           100%;
  max-width:       800px; 
  margin:          auto;
  justify-content: center;
  align-items:     center; 
  align:           center;
  place-items:     center;
  text-align:      center;
  box-sizing:      border-box;
}

.espace_haut{
  
  height: 20px;
}

.espace_bas{
  
  height: 20px;
}

@media only screen and (max-width: 465px) {

  .root {
    background-size:  100% 100%;
    background-image: url("https://home.lovelightfactory.com/images/fond2-sd.jpg"); 
  }

}


.texte {
 text-wrap: wrap;
 text-align: justify;
}

.accordion {

  width:         100%;
  outline:       none;
  font-size:     16px;
  color:         #ffffff;
 /* border-radius: 5px;
  border:        2px solid #444;
  background:    linear-gradient(#333,#222); */
  margin:        0;
  padding:       10px;
  box-sizing:    border-box;  
}


.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
	  box-sizing:    border-box;  

}

.accordion__button {

  width:         100%;
  outline:       none;
  font-size:     16px;
  color:         #ffffff;
  border-radius: 5px;
  border:        2px solid #444;
  background:    linear-gradient(#333,#222);
  margin:        0;
  padding:       0 10px;
  box-sizing:    border-box;  
  cursor: pointer;
  box-sizing:    border-box;  
  
 text-wrap: wrap;
 text-align: justify;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
	  box-sizing:    border-box;  
	background: #000000;

}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media only screen and (max-width: 1920px) {
  .root {
    height:           1080px; 
    background-size:  1920px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1920-1080.jpg"); 
  }
 }

@media only screen and (max-width: 1440px) and (orientation: landscape) {
  .root {
    height:           1024px; 
    background-size:  1440px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-L.jpg"); 
  }
 }

@media only screen and (max-width: 1440px) and (orientation: portrait) {
  .root {

    height:        1170px;

    background-size:  1024px;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-1368x1024-P.jpg"); 
  }
 }
 
@media only screen and (max-width: 460px) {
  .root {

  background:      #000000;
  background-repeat: no-repeat;
  
    margin-top:   0px;

    height:        800px;

    background-size:  100%;
    background-image: url("https://home.lovelightfactory.com/images/fond-welcome-464x934-P.jpg"); 
  }
	
  .contain {
    width: 90%;
	}
 }
