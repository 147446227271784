.root {
  height:        fit-content;
  width:         100%;
  outline:       none;
  font-size:     100%;
  color:         #ffffff;
  border-radius: 5px;
  border:        0px;
  caret-color:   #FFFF00;
  padding:       0 10px;
  margin:        auto;
  box-sizing:    border-box;
  
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}

.left {
  grid-column: 1;
  grid-row: 1;
  align: left;
  text-align: left;
  margin-left: 5px;
  place-items: left;
}

.right {
  grid-column: 2;
  grid-row: 1;
  align: right;
  text-align: right;
  margin-right: 5px;
  place-items: right;
}



@media only screen and (max-width: 430px) {

.root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
}

.left {
  grid-column: 1;
  grid-row: 1;
  align: left;
  margin-left: 5px;
    place-items: left;
}

.right {
  grid-column: 1;
  grid-row: 2;
  align: right;
  margin-right: 5px;
    place-items: right;
}
}

.firstname_lastname {
  font-weight: 600;
   align: left;
   margin-left:0
}

.email {
   align: left;
   margin-left:0
}

.trigger {
  margin: 0;
  height: fit-content;
  width: fit-content;
  font-size: 15px;
  padding: 0 15px;
  font-weight: 600;
  letter-spacing: 1px;
}
