.root {

}

.sticky {
  position:         fixed;
  top:              auto;
  bottom:           0;
  left:             0;
  z-index:          4000;
  place-items:      center;
  justify-content:  center;
  background-color: black;
  width:            100%;
  height:           fit-content;
  text-align:       center;
}

.button{
  height:           18px;
  width:            fit-content;
  font-size:        12px;
  color:            white;
  background-color: black;
  padding:          0 5px;
  margin-top:       5px;	
  margin-bottom:    5px;	
  margin-right:     8px;
  margin-left:      8px;
  border:           0px solid #FFFFFF;
  cursor:           pointer;
  font-family:      'Barlow Condensed',sans-serif;
  letter-spacing:   1px;
  font-weight:      600;
}


.button:hover {
  color:            black;
  background-color: white;
}


@media only screen and (max-width: 460px) {

 .sticky {
    height:         25px;
    padding-bottom: 2px;
 }

 .button{
    height:         15px;
    font-size:      10px;
    margin-top:     2px;	
    margin-bottom:  0px;	
    margin-left:    5px;
    margin-right:   5px;
    letter-spacing: 0.8px;
  }
}

